import { Typography } from "@mui/material";
import { width } from "@mui/system";
import { ReactNode } from "react";
import SkeletonLoader from "../SkeletonLoader";

import {
  Base,
  BaseBody,
  BaseFooter,
  BaseHeader,
  BaseHeaderGroupHeading,
  BaseHeaderLead,
  BaseHeaderGroupBody,
  BaseLead,
  BaseBrand,
  BaseImage,
} from "./styles";

/********************************************** */
// Lead
/********************************************** */

interface LeadProps {
  children?: ReactNode;
}

const Lead = ({ children }: LeadProps) => <BaseLead>{children}</BaseLead>;

/********************************************** */
// Header
/********************************************** */

interface HeaderProps {
  title?: string;
  lead?: string;
  loading?: boolean;
  children?: ReactNode;
  leadChildren?: ReactNode;
  companyBrandImageUrl?: string;
  showBrandImageOverTitle?: boolean;
  variant?: "standard" | "form" | "thin";
}

const Header = ({
  children,
  title,
  variant,
  loading = false,
  lead,
  companyBrandImageUrl,
  showBrandImageOverTitle,
  leadChildren,
}: HeaderProps) => {
  const hasImage =
    (companyBrandImageUrl && companyBrandImageUrl !== "") || false;

  return (
    <BaseHeader variant={variant!}>
      {typeof title !== "undefined" && (
        <BaseHeaderGroupHeading>
          <Typography variant="h2">
            {(!showBrandImageOverTitle || !hasImage) && (
              <SkeletonLoader loading={loading!} width={250}>
                {title}
              </SkeletonLoader>
            )}
            {showBrandImageOverTitle && (
              <SkeletonLoader loading={loading!} width={250}>
                {hasImage && (
                  <BaseBrand>
                    <BaseImage src={companyBrandImageUrl} alt={title} />
                  </BaseBrand>
                )}
              </SkeletonLoader>
            )}
          </Typography>
          {lead && <BaseHeaderLead variant="body1">{lead}</BaseHeaderLead>}
          {leadChildren && leadChildren}
        </BaseHeaderGroupHeading>
      )}
      {children && (
        <BaseHeaderGroupBody hasLead={typeof lead !== "undefined"}>
          {children}
        </BaseHeaderGroupBody>
      )}
    </BaseHeader>
  );
};

/********************************************** */
// Footer
/********************************************** */

interface FooterProps {
  children?: ReactNode;
  align?: "left" | "center" | "right";
  variant?: "standard" | "narrow" | "stretch";
}

const Footer = ({ children, align = "right", variant }: FooterProps) => (
  <BaseFooter align={align} variant={variant!}>
    {children}
  </BaseFooter>
);

/********************************************** */
// Body
/********************************************** */

interface BodyProps {
  children?: ReactNode;
  variant?: "standard" | "narrow" | "stretch";
}

const Body = ({ children, variant = "standard" }: BodyProps) => (
  <BaseBody variant={variant!}>{children}</BaseBody>
);

/********************************************** */
// Main
/********************************************** */

interface ViewProps {
  children?: ReactNode;
  footer?: ReactNode;
  width?: string;
}

const View = ({ children, width }: ViewProps) => (
  <Base width={width}>{children}</Base>
);

View.Header = Header;
View.Body = Body;
View.Footer = Footer;
View.Lead = Lead;

export default View;
