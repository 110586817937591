import styled from "@emotion/styled";
import em from "src/utils/em";
import mq from "src/utils/mq";
import space from "src/utils/space";

export const Base = styled.div`
  font-size: ${em(10)};

  ${mq("sm")} {
    font-size: ${em(15)};
  }
`;
