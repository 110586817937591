import { ReactNode } from "react";
import { Base } from "./styles";

/********************************************** */
// Row Item
/********************************************** */

interface HomePageTextProps {
  children: ReactNode;
}

const HomePageText = ({ children }: HomePageTextProps) => (
  <Base>{children}</Base>
);

export default HomePageText;
