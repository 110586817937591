import { Backdrop, CircularProgress } from "@mui/material";

import { Base } from "./styles";

interface FullScreenLoaderProps {
  loading?: boolean;
}

const FullScreenLoader = ({ loading = false }: FullScreenLoaderProps) => (
  <Base open={loading!}>
    <CircularProgress />
  </Base>
);

export default FullScreenLoader;
